<template>
  <div class="login">
    <div v-show="!showAgreement">
      <div class="login-header">
        <div class="login-header-left flex items-center">
          <img src="@images/components/login/logo.png" />
          <span>青鹿教学云平台</span>
        </div>
        <div class="login-header-right">
          <span>当前服务器版本：{{ server.version }}</span>
          <span class="register" @click="handlerRegister">
            {{ formType === FORMTYPE.REGISTER ? '返回登录' : '系统注册' }}
          </span>
        </div>
      </div>
      <!-- 登录表单 -->
      <div class="login-main">
        <div class="login-form-box">
          <img src="@images/components/login/background.png" />
          <!-- 登录 -->
          <loginForm
            v-show="formType === FORMTYPE.LOGIN"
            @login="loginDispatch"
            @forget="forgetPassword"
            @read="showAgreement = true"
          />
          <!-- 找回密码 -->
          <passwordForm
            v-if="formType === FORMTYPE.FORGETPW"
            :defaultSchool="currentSchool"
            @back="formType = FORMTYPE.LOGIN"
          />
          <!-- 短信验证 -->
          <verifyForm v-if="formType === FORMTYPE.VERIFY" :phone="verifyPhone" @back="verifyBack" />
          <!-- 系统注册 -->
          <registerForm v-if="formType === FORMTYPE.REGISTER" :server="server" />
        </div>
      </div>

      <!-- 推荐浏览器提示 -->
      <div class="recommend-browser">
        为保证平台的正常使用，建议您尽量使用【谷歌浏览器】或【360浏览器-极速模式】进行浏览。
      </div>

      <copyrightHtml />
    </div>

    <!-- 用户协议 -->
    <agreement v-show="showAgreement" @back="showAgreement = false" />
  </div>
</template>

<script>
/**
 * 登录页
 */
// components
import loginForm from './components/form/login/index.vue';
import passwordForm from './components/form/forgetPassword';
import verifyForm from './components/form/verify';
import registerForm from './components/form/register';
import agreement from './components/agreement/index';
import copyrightHtml from '@/components/copyrightHtml';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';
// api
import { getLicense } from '@/api/common';
// vuex
import { mapGetters } from 'vuex';

// 表单类型常量
const FORMTYPE = {
  LOGIN: 1,
  FORGETPW: 2,
  VERIFY: 3,
  REGISTER: 4,
};

export default {
  name: 'login',
  mixins: [loginDispatchMixin],
  components: {
    loginForm,
    passwordForm,
    verifyForm,
    registerForm,
    agreement,
    copyrightHtml,
  },
  data() {
    return {
      // 当前服务器信息
      server: {
        version: '--',
        id: '',
      },

      // 表单类型：1-登录 2-找回密码 3-短信验证 4-系统注册
      formType: FORMTYPE.LOGIN,
      FORMTYPE: FORMTYPE,
      // 当前所选学校名称
      currentSchool: {},
      // 短信验证手机号
      verifyPhone: '',

      // 阅读用户协议
      showAgreement: false,
    };
  },
  computed: {
    ...mapGetters(['hostUrl', 'token']),
  },
  methods: {
    // 获取系统版本
    getLicense() {
      getLicense().then((res) => {
        this.server = res.result;
        this.showUpgradeResult(res.result.version);
      });
    },

    // 显示升级结果
    showUpgradeResult(current_version) {
      let showUpgradeStatus = this.$store.state.upgrade.showUpgradeStatus;

      if (!showUpgradeStatus) return;

      let message = `重启成功，当前版本V${current_version}`;
      let type = 'success';

      if (showUpgradeStatus == 11) {
        message = '升级失败，已自动回滚至升级前版本。请下载升级日志查看失败原因';
        type = 'error';
      }

      this.$message({
        message,
        type,
        duration: 0,
        showClose: true,
      });

      // 进入后台管理页，弹窗显示升级结果信息
      this.$store.commit('upgrade/SET_CHECK_UPGRADE_RESULT', true);

      // clear
      this.$store.commit('upgrade/SET_SHOW_UPGRADE_STATUS', '');
    },

    // 忘记密码
    forgetPassword(school) {
      this.formType = FORMTYPE.FORGETPW;
      this.currentSchool = school;
    },

    // 系统注册
    handlerRegister() {
      this.formType = this.formType === FORMTYPE.REGISTER ? FORMTYPE.LOGIN : FORMTYPE.REGISTER;
    },

    // 手机验证
    verificationCode(mobileNo) {
      this.formType = FORMTYPE.VERIFY;
      this.verifyPhone = mobileNo;
    },

    // 手机验证返回
    verifyBack() {
      this.formType = FORMTYPE.LOGIN;
      // 登录表单重置
      let remember = localStorage.getItem('remember')
        ? JSON.parse(decodeURIComponent(localStorage.getItem('remember')))
        : {};
      this.$children[0].form.username = remember.username || '';
      this.$children[0].form.password = '';
    },
  },
  created() {
    this.getLicense();

    // 默认参数获取
    let { formType, verifyPhone } = this.$route.params;
    formType && (this.formType = formType);
    verifyPhone && (this.verifyPhone = verifyPhone);

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener(
        'popstate',
        () => {
          window.location.reload();
        },
        false
      );
    }
  },
  mounted() {
    window.entryTitle && (document.title = window.entryTitle + '-登录');
  },
};
</script>

<style lang="scss" scoped src="./login.scss"></style>
