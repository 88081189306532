<template>
  <el-select
    v-model="schoolId"
    class="school-select"
    size="small"
    popper-class="school-select-popper"
    placeholder="请选择学校"
    :title="schoolName"
    @change="selectSchool"
  >
    <i slot="prefix" class="iconfont icon-icon_denglu_xuexiao"></i>
    <div slot="empty" class="pd-t-6">
      <div class="select-input">
        <i class="iconfont icon-icon_sousuo"></i>
        <input
          ref="searchInput"
          type="text"
          placeholder="请输入两个以上连续的关键字"
          v-model="schoolKey"
          @keyup.enter="getSchoolList"
          @keyup="debouncedOnSearch"
        />
      </div>
      <div class="no-data">无数据</div>
    </div>
    <div class="select-input" v-if="schoolList.length > 0">
      <i class="iconfont icon-icon_sousuo"></i>
      <input
        ref="searchInput"
        type="text"
        placeholder="请输入两个以上连续的关键字"
        v-model="schoolKey"
        @keyup.enter="getSchoolList"
        @keyup="debouncedOnSearch"
      />
    </div>
    <el-option
      v-for="item in schoolList"
      :key="item.id"
      :value="item.id"
      :label="item.schoolName"
      :title="item.schoolName"
      :class="['overflow-ellipsis', { active: item.id === schoolId }]"
    ></el-option>
  </el-select>
</template>

<script>
// api
import { getSchoolByKeyword } from '@/api/common';
// components
import { select, option } from 'element-ui';
// debounce
import debounce from 'throttle-debounce/debounce';

export default {
  name: 'schoolSelect',
  components: {
    ElSelect: select,
    ElOption: option,
  },
  props: {
    // 默认选中（包含id, schoolName）
    defaultValue: Object,
  },
  data() {
    return {
      schoolId: '',
      schoolName: '',
      schoolKey: '',
      schoolList: [],
      manualSelected: false, // 手动选中
    };
  },
  watch: {
    defaultValue: {
      immediate: true,
      async handler(val) {
        if (!this.manualSelected && val && val.id) {
          // 默认自动选中
          this.schoolKey = val.schoolName;
          await this.getSchoolList(false);
          this.schoolId = this.schoolList.find((s) => s.id === val.id) ? val.id : val.schoolName;
          this.schoolName = val.schoolName;
          this.$emit('select', val);
        }
      },
    },
    schoolList(val, oldVal) {
      if ((val.length === 0 || oldVal.length === 0) && val !== oldVal) {
        // 搜索框切换时保留焦点（elementui select options长度为0是会显示empty，故有两个input搜索框）
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
  },
  methods: {
    // 获取学校列表
    // remind {Boolean} 是否提醒输入
    getSchoolList(remind) {
      return new Promise((resolve) => {
        if (this.schoolKey.trim().length < 2) {
          this.schoolList = [];
          remind !== false && this.$message.warning('请输入两个以上连续的关键字');
          return;
        }
        getSchoolByKeyword({
          schoolNameFuzzy: this.schoolKey,
        }).then((res) => {
          this.schoolList = res.result.length ? res.result : [];
          resolve();
        });
      });
    },
    // 选择学校
    selectSchool(id) {
      let school = this.schoolList.find((item) => item.id == id);
      this.schoolName = school.schoolName;
      this.$emit('select', school);
      this.manualSelected = true;
    },
  },
  created() {
    this.debouncedOnSearch = debounce(800, () => {
      this.getSchoolList(false);
    });
  },
};
</script>

<style lang="scss" scoped>
.school-select {
  i {
    font-size: 20px;
    color: #2c2f2e;
    line-height: 32px;
  }
}

.select-input {
  display: flex;
  align-items: center;
  margin: 6px 16px 10px;
  border-bottom: 1px solid #d6dedc;
  line-height: 32px;

  i {
    color: #c0c4cc;
  }

  input {
    flex: 1;
    margin-left: 5px;
    outline: none;

    &::-webkit-input-placeholder {
      color: #bec3c2;
    }
  }
}

.no-data {
  line-height: 160px;
  text-align: center;
  color: #999;
}
</style>

<style lang="scss">
.school-select-popper {
  height: 304px;
  width: 320px;

  .el-select-dropdown__wrap {
    max-height: 304px;
    overflow-x: auto;
  }
}
</style>
