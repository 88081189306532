<template>
  <div class="copyright">
    Copyright © 2017 - {{ new Date().getFullYear() }} <a href="https://www.qljy.com">广州青鹿教育科技有限公司</a> All
    Rights Reserved
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'copyrightHtml',
  mounted() {
    let copyright = document.querySelector('.copyright');
    window.copyrightHTML && copyright && (copyright.innerHTML = window.copyrightHTML);
  },
};
</script>

<style lang="scss" scoped>
.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 68px;
  text-align: center;
  color: #6d7171;
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #8e9093;
    }
  }
}
</style>
