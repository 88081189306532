// api
import { getLoginDispatch } from '@/api/common';
// util
import { getRouteByPermission } from '@/utils/permission';
import { removeToken } from '@/utils/cookies';

export default {
  methods: {
    // 登录跳转
    loginDispatch() {
      return new Promise((resolve, reject) => {
        getLoginDispatch()
          .then(async (res) => {
            /**
             * @param nextPage 跳转标识
             * @param mobileNo 手机号码
             * @param mobileBinding false-需要绑定手机 true-需要手机短信验证 空-不处理
             */
            const { nextPage, mobileNo } = res.result;

            let targetRouter = '';
            switch (nextPage) {
              case 'schoolSettingPage': // 独立学校服务器配置页面
                targetRouter = '/back-stage/school-server-config';
                break;
              case 'cloudSettingPage': // 云层配置页面
                targetRouter = '/back-stage/education-init-config';
                break;
              case 'baseManagePage': // 后台管理页面
                await getRouteByPermission();
                targetRouter = await this.getRedirect('/back-stage');
                break;
              case 'verificationCodePage': // 手机验证码页面
                if (this.verificationCode) {
                  // 有自定义短信验证方法
                  this.verificationCode(mobileNo || '');
                } else {
                  targetRouter = {
                    name: 'login',
                    params: {
                      formType: 3,
                      verifyPhone: mobileNo || '',
                    },
                  };
                }
                removeToken();
                break;
              case 'mainIndexPage': // 首页
                await getRouteByPermission();
                targetRouter = await this.getRedirect('/home');
                break;
            }

            if (targetRouter) {
              // https://github.com/Armour/vue-typescript-admin-template/issues/150
              this.$router
                .push(targetRouter)
                .then(() => {})
                .catch(() => {});
            }

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 获取redirect（登录后跳转路由）
    async getRedirect(router) {
      // 检查是否拥有该路由的权限
      const checkRouteIncludes = (redirect) => {
        const getPaths = (routes, rootPath = '') => {
          routes.map((route) => {
            let path = `${rootPath}${route.path}`;
            paths.push(path);
            if (route.children) {
              getPaths(route.children, `${path}/`);
            }
          });
        };

        let [...routes] = this.$store.state.route.routes;
        let paths = [];

        getPaths(routes);
        return paths.includes(redirect);
      };

      const redirect = this.$route.query.redirect;

      if (redirect && redirect !== '/') {
        if (checkRouteIncludes(redirect)) {
          return redirect;
        }
      }
      return router;
    },
  },
};
