// components
import { form, formItem, input, checkbox, button } from 'element-ui';
import schoolSelect from '../../schoolSelect';
// utils
import { rsaEncryption } from '@/utils/common';

export default {
  name: 'loginForm',
  components: {
    ElForm: form,
    ElFormItem: formItem,
    ElInput: input,
    ElCheckbox: checkbox,
    ElButton: button,
    schoolSelect,
  },
  data() {
    // 自定义学校验证
    const checkSchool = (rule, value, callback) => {
      if (value === '' && !this.form.username.includes('@')) {
        callback(new Error('学校名不能为空'));
      }
      callback();
    };

    return {
      ctx: window.$ctx, // 当前访问路径

      form: {
        schoolId: '',
        schoolName: '',
        username: '',
        password: '',
        isRemember: false,
        hasRead: true,
      },
      rules: {
        schoolName: [{ validator: checkSchool, trigger: 'change' }],
        username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      },

      // 用于 form action 提交的帐号密码
      j_username: '',
      j_password: '',

      showPassword: false, // 是否展示密码
      currentSchool: {}, // 当前所选学校
      submitLoading: false, // 提交中

      showOtherPlatform: false, // 显示其他平台（其他帐号登录）
    };
  },
  computed: {
    schoolRequired() {
      return !this.form.username.includes('@');
    },
  },
  methods: {
    // 学校选择
    changeSchool(data) {
      this.form.schoolId = data.id;
      this.form.schoolName = data.schoolName;
      this.currentSchool = data;
      localStorage.setItem('zhkt_school', JSON.stringify(data));
    },
    // 切换记住帐号
    changeRemember() {
      let remember = {
        checked: this.form.isRemember,
        username: this.form.username.trim(),
      };
      localStorage.setItem('remember', encodeURIComponent(JSON.stringify(remember)));
    },
    // 忘记密码
    forgetPassword() {
      this.$emit('forget', this.currentSchool);
    },
    // 打开用户协议
    openLicenseTxt() {
      this.$emit('read');
    },
    // 打开其他平台
    openOtherWeb(name) {
      if (!this.form.hasRead) {
        this.promptCheckAgreement();
        return;
      }

      let href = `${window.$ctx}/OAuth2/${name}/web/authorize.do`;
      let aLink = document.createElement('a');
      aLink.href = href;
      aLink.rel = 'noreferrer';
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    },

    // 跳转到中转页（其他平台登录验证）
    goToSyncRedirect(name) {
      this.$router.push({
        path: '/sync-redirect',
        query: {
          thirdProjectCode: name,
        },
      });
    },

    // 提示勾选《用户协议及隐私政策》
    promptCheckAgreement() {
      this.$message({
        message: '请勾选《用户协议及隐私政策》',
        type: 'warning',
      });
    },

    // 登录
    login() {
      this.$refs['loginForm'].validate(async (valid) => {
        if (valid) {
          if (!this.form.hasRead) {
            this.promptCheckAgreement();
            return;
          }

          this.submitLoading = true;
          const username = this.form.username.includes('@')
            ? this.form.username
            : `${this.form.username}@${this.currentSchool.schoolKey}`;

          if (process.env.NODE_ENV === 'development') {
            // 本地开发环境，直接调登录接口
            try {
              await this.$store.dispatch('user/login', {
                userInfo: {
                  j_username: username.trim(),
                  j_password: rsaEncryption(this.form.password.trim()),
                  deviceType: 3,
                  isRsaPassword: true,
                },
              });
              this.submitLoading = false;
              this.$emit('login');
            } catch (err) {
              this.$store.dispatch('user/resetToken');
              this.submitLoading = false;
            }
          } else {
            // form action post 方式登录，后台重定向至中转页 sync-redirect
            this.j_username = username.trim();
            this.j_password = rsaEncryption(this.form.password.trim());

            this.$nextTick(() => {
              this.$refs.loginForm.$el.submit();
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  mounted() {
    // 读取缓存
    let cacheSchool = localStorage.getItem('zhkt_school');
    if (cacheSchool && JSON.parse(cacheSchool)) {
      this.currentSchool = JSON.parse(cacheSchool);
    }

    let remember = localStorage.getItem('remember')
      ? JSON.parse(decodeURIComponent(localStorage.getItem('remember')))
      : { checked: false };
    this.form.isRemember = remember.checked;
    remember.checked && (this.form.username = remember.username);
  },
};
