<template>
  <div class="verification-code">
    <el-input
      class="code-input"
      size="small"
      placeholder="请输入验证码"
      name="code"
      v-model="code"
      @input="inputCode"
      @keyup.native.enter="handleKeyup"
    >
      <i slot="prefix" class="iconfont icon-icon_denglu_yanzhengma"></i>
    </el-input>
    <div class="code-btn" @click="getVerificationCode">{{ buttonContent }}</div>
  </div>
</template>

<script>
// components
import { input } from 'element-ui';
// api
import { sendCaptcha } from '@/api/user';

export default {
  name: 'verificationCode',
  components: {
    ElInput: input,
  },
  props: {
    // 是否需要先选择学校
    mustSchool: Boolean,
    // 学校服务地址（mustSchool为true时使用）
    serverUrl: {
      type: String,
      default: '',
    },
    // 手机号码
    phone: [String, Number],
    // 类型（1-重置密码 2-忘记密码 20-短信验证）
    type: [String, Number],
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
      buttonContent: '获取验证码',
      disabled: false,
      timeSetting: undefined, // 计时器
    };
  },
  methods: {
    // 获取验证码
    getVerificationCode() {
      // 发送验证码倒计时中不可以操作
      if (this.disabled) {
        return;
      }
      // 是否选择了学校
      if (this.mustSchool && !this.serverUrl) {
        this.$emit('getError', 'schoolName');
        return;
      }
      // 手机号码格式验证
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$emit('getError', 'phone');
        return;
      }

      sendCaptcha({ mobile: this.phone, type: this.type }, this.serverUrl).then(() => {
        let count = 60;
        countDown(this, count);
      });

      // 60秒倒计时，防止频繁点击
      function countDown(vm, count) {
        vm.disabled = true;
        vm.buttonContent = `已发送(${count}s)`;
        if (count > 0) {
          vm.timeSetting = setTimeout(() => {
            countDown(vm, count - 1);
          }, 1000);
        } else {
          vm.buttonContent = '重新获取';
          vm.disabled = false;
          clearTimeout(vm.timeSetting);
          vm.timeSetting = undefined;
        }
      }
    },
    // 输入验证码
    inputCode(value) {
      // this.$emit('input', value.replace(/[^\d]/g, ''));
      this.$emit('input', value);
    },
    // 键盘 enter 事件
    handleKeyup() {
      this.$emit('enter');
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss"></style>
