<template>
  <div class="login-form">
    <p class="form-title mg-t-26">系统注册</p>
    <div class="form-item register-form">
      <p>设备序列号</p>
      <div>{{ registerForm.id || server.id }}</div>
    </div>
    <div class="form-item register-form">
      <p>有效期至</p>
      <div>{{ registerEndDate }}<span v-if="!registerEndDate">未注册</span></div>
    </div>
    <el-upload
      class="upload-demo"
      :action="`${ctx}/license/save.do`"
      :show-file-list="false"
      :on-success="handleSuccess"
      :on-error="handleError"
    >
      <el-button class="submit-btn" style="margin-top: 72px" type="primary" :loading="submitLoading">
        上传license
      </el-button>
    </el-upload>
    <div class="register-version">当前服务器版本：{{ registerForm.version || server.version }}</div>
  </div>
</template>

<script>
// components
import { upload, button } from 'element-ui';

export default {
  name: 'registerForm',
  components: {
    ElUpload: upload,
    ElButton: button,
  },
  props: {
    server: Object,
  },
  data() {
    return {
      ctx: window.$ctx,
      submitLoading: false, // 提交中

      // 注册系统表单
      registerForm: {
        version: '',
        id: '',
        validDate: '',
      },
    };
  },
  computed: {
    registerEndDate() {
      return this.registerForm.validDate || this.server.validDate;
    },
  },
  methods: {
    // 上传license成功回调
    handleSuccess(res) {
      if (res.status === 0) {
        this.registerForm = res.result;
        this.$message.success('上传license文件成功');
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传license失败回调
    handleError(err) {
      console.error(err);
      this.$message.error('上传license文件失败');
    },
  },
};
</script>

<style lang="scss" scoped src="./form.scss"></style>
<style lang="scss" scoped>
.form-title {
  margin-top: 20px;
  padding-bottom: 20px;
}
.register-form {
  & > p {
    color: #bec3c2;
    font-size: 14px;
    line-height: 32px;
  }

  & > div {
    @include l-h(32px);
    font-size: 14px;

    span {
      color: #bec3c2;
    }
  }
}
.register-version {
  margin-top: 20px;
  text-align: center;
  color: #808080;
}
</style>
