<template>
  <!-- 找回密码 -->
  <el-form :model="form" :rules="rules" ref="form" class="login-form">
    <p class="form-title mg-t-8">找回密码</p>

    <el-form-item prop="schoolName" class="form-item" style="margin-top: 30px">
      <school-select :defaultValue="defaultSchool" class="form-input" @select="changeSchool" />
    </el-form-item>

    <el-form-item prop="phone" class="form-item">
      <el-input class="form-input" size="small" placeholder="请输入手机号码" v-model="form.phone">
        <i slot="prefix" class="iconfont icon-icon_shoujibangding-n"></i>
      </el-input>
    </el-form-item>

    <el-form-item prop="code" class="form-item code-form">
      <verificationCode
        v-model="form.code"
        :phone="form.phone"
        type="2"
        mustSchool
        :serverUrl="serverUrl"
        @getError="checkForm"
      />
    </el-form-item>

    <el-form-item prop="password" class="form-item">
      <el-input
        class="form-input"
        size="small"
        autocomplete="new-password"
        placeholder="请输入密码"
        :type="showPassword ? 'text' : 'password'"
        v-model="form.password"
      >
        <i slot="prefix" class="iconfont icon-icon_xiugaimima-n"></i>
        <i
          slot="suffix"
          :class="'iconfont cursor-pointer icon-icon_denglu_yanjing' + (showPassword ? '-zheng' : '_bi')"
          style="color: #bcc1c0; font-size: 20px"
          @click="showPassword = !showPassword"
        ></i>
      </el-input>
      <div class="form-item-tip">8-24位，至少包含数字、字母或特殊字符</div>
    </el-form-item>

    <el-form-item>
      <el-button
        class="submit-btn"
        style="margin-top: 64px"
        type="primary"
        :loading="submitLoading"
        @click="updatePassword"
      >
        重置密码
      </el-button>
      <el-button type="text" class="text-btn" @click="$emit('back')">去登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// components
import { form, formItem, input, button } from 'element-ui';
import schoolSelect from '../schoolSelect';
import verificationCode from '../verificationCode';
// api
import { updatePassword } from '@/api/user';
// utils
import { checkPhone } from '@/utils/validate';

export default {
  name: 'forgetPasswordForm',
  components: {
    ElForm: form,
    ElFormItem: formItem,
    ElInput: input,
    ElButton: button,
    schoolSelect,
    verificationCode,
  },
  props: {
    defaultSchool: Object,
  },
  data() {
    // 自定义手机号验证
    const validatePhone = (rule, value, callback) => {
      const checkMsg = checkPhone(value);
      if (checkMsg === true) {
        callback();
      } else {
        callback(new Error(checkMsg));
      }
    };
    // 自定义密码验证
    const checkPassword = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error('请输入新密码'));
      } else {
        let password = this.form.password;

        let reg1 =
          /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^0-9a-zA-Z]))[^\s\u4e00-\u9fa5]+)$/;
        if (password.length < 8 || password.length > 24 || !reg1.test(password)) {
          callback(new Error('密码格式不正确'));
        }

        let reg2 = /<.*>/;
        if (reg2.test(password)) {
          callback(new Error('不允许输入非法字符'));
        }

        callback();
      }
    };

    return {
      form: {
        schoolName: '',
        schoolId: '',
        phone: '',
        code: '',
        password: '',
      },
      rules: {
        schoolName: [{ required: true, message: '学校名不能为空', trigger: 'change' }],
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ validator: checkPassword, trigger: 'blur' }],
      },

      showPassword: false, // 是否展示密码

      currentSchool: {}, // 当前学校信息

      submitLoading: false, // 提交中
    };
  },
  computed: {
    serverUrl() {
      return this.currentSchool.serverUrl || this.defaultSchool.serverUrl || '';
    },
  },
  methods: {
    // 学校选择
    changeSchool(data) {
      this.currentSchool = data;
      this.form.schoolId = data.id;
      this.form.schoolName = data.schoolName;
    },
    // 部分表单字段验证
    // name [string] 验证字段
    checkForm(name) {
      console.log(name);
      this.$refs.form.validateField(name);
    },
    // 重置密码
    updatePassword() {
      if (this.submitLoading) return;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          updatePassword({
            mobileNo: this.form.phone,
            code: this.form.code,
            password: this.form.password,
            confirmPassword: this.form.password,
          })
            .then(() => {
              this.$message.success('重置密码成功');
              this.submitLoading = false;
              this.$emit('back');
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.form = {
        schoolName: '',
        schoolId: '',
        phone: '',
        code: '',
        password: '',
      };
    });
  },
};
</script>

<style lang="scss" scoped src="./form.scss"></style>
